<template>
	<div>
		<div v-if="registerState.currentStep === 0" class="m23 wrapper">
			<img :src="require('@/assets/img/intro_1.png')" />
			<a @click="goPage('GuideRegisterForCorp')">법인회원 가입안내</a>
			<!-- 버튼 -->
			<div class="btn-app">
				<button type="button" class="btn-select" @click="doStep1">
					가입하기
				</button>
			</div>
		</div>

		<AppPermission
			v-if="registerState.currentStep === 1"
			@appPermissionCallback="appPermissionCallbackListener"
		/>

		<RegisterTerms
			v-if="registerState.currentStep === 3"
			@registerTemrsCallback="registerTemrsCallbackListener"
		/>

		<TermsViewer
			v-if="showTermsViewer === true"
			:termsViewerType="termsViewerType"
			@registerTemrsCallback="registerTemrsCallbackListener"
		/>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import AppPermission from '@/components/AppPermission';
import RegisterTerms from '@/components/terms/RegisterTerms';
import TermsViewer from '@/components/terms/TermsViewer';

export default {
	name: 'RegiserPostService',

	components: {
		AppPermission,
		RegisterTerms,
		TermsViewer,
	},

	computed: {
		...mapGetters(['registerState']),
	},

	data() {
		return {
			step: 0,
			showTermsViewer: false,
			termsViewerType: '',
		};
	},

	created() {
		console.debug('created!!!', this.registerState.currentStep);

		// window.checkPermissionCallback = this.checkPermissionCallback;
	},

	methods: {
		...mapMutations([
			'saveRegisterProgressState',
			'saveRegisterStepState',
			'updateLoadingProgressState',
		]),
		...mapActions(['doPostSeriveRegister']),

		appPermissionCallbackListener(data) {
			console.debug('appPermissionCallbackListener', data);
			this.doStep2();
		},

		doStep1() {
			this.doPostSeriveRegister();
			console.debug('saveRegisterStepState!!!', this.registerState.currentStep);
		},

		doStep2() {
			this.saveRegisterStepState(2);
			window.open(process.env.VUE_APP_INTENDITY_URL, '_blank');

			//휴대폰 본인인증 화면 호출
			//TODO 휴대혼 본인인증 화면 호출한다.

			// 휴대폰 본인인증 콜백 설정
			window.doUserIdentityCallback = res => {
				window.doRegisterIdentityCallback = null;
				console.debug('doUserIdentityCallback', res);
				if (res && res.RESULT) {
					if (res.RESULT === 'OK') {
						this.doStep3();
					}
				}
			};
		},

		doStep3() {
			this.saveRegisterStepState(3);
			// this.$router.push({name: 'TosForm'});
		},

		registerTemrsCallbackListener(res) {
			console.debug('registerTemrsCallbackListener', res);

			if (res.ACTION === 'VIEWER') {
				this.termsViewerType = res.DATA;
			} else if (res.ACTION === 'COMP') {
				//
			} else if (res.ACTION === 'CANCEL') {
				//
			}
		},

		doCheck() {
			if (!this.saveRegisterProgressState.hasPermission) {
				// 네이티브에 웹 권한 설정 여부를 요청한다.
				//TODO 네이티브에 웹 권한 설정 여부를 요청한다.

				console.debug('window.doCheckPermissionCallback');
				// 앱권한 응답 콜백 설정
				window.doCheckPermissionCallback = res => {
					window.doCheckPermissionCallback = null;
					console.debug('doCheckPermissionCallback', res);
					if (res && res.RESULT) {
						if (res.RESULT === 'OK') {
							// store.commit('savePermissionState', true);
							// next({ name: 'Register' });
						} else {
							// next({ name: 'GuideAppPermission' });
						}
					}
				};
				return;
				// next(false);
			}

			if (
				this.saveRegisterProgressState.hasPermission &&
				!this.saveRegisterProgressState.hasIndentity
			) {
				window.open(process.env, '_blank');

				//휴대폰 본인인증 화면 호출
				//TODO 휴대혼 본인인증 화면 호출한다.

				// 휴대폰 본인인증 콜백 설정
				window.doRegisterIdentityCallback = res => {
					window.doRegisterIdentityCallback = null;
					console.debug('doRegisterIdentityCallback', res);
					if (res && res.RESULT) {
						if (res.RESULT === 'OK') {
							// store.commit('saveIndentityState', true);
							// next({ name: 'Register' });
						}
					}
				};

				// next(false);
			}

			if (
				this.saveRegisterProgressState.hasPermission &&
				this.saveRegisterProgressState.hasIndentity &&
				!this.saveRegisterProgressState.hasAgreeTerm
			) {
				// next({
				// 	name: 'TosForm',
				// });
			}
		},

		goPage(what) {
			// this.saveRegisterProgressState(true);
			this.$router.push({ name: what, params: {} });
		},

		doRegister() {
			// native에 웹 권한 설정 여부를 요청한다.
			// this.goPage('Register');
			this.step = 1;
		},

		checkPermissionCallback(res) {
			console.debug(res);
			// 앱권한이 없을 경우 앱 권한 요청 화면으로 전환
			const hasAppPermission = false;
			if (!hasAppPermission) {
				this.$router.push({ name: 'GuideAppPermission' });
			} else {
				//
			}
		},
	},
};
</script>

<style></style>
