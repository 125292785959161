<template>
	<div v-if="1 === 2" div class="m35 wrapper">
		<div class="top-nav">
			<div class="icon-txt">
				<a @click="$router.go(-1)">
					<div class="top-iconBack">
						<img :src="require('@/assets/img/ic_back.svg')" alt="뒤로가기" />
					</div>
				</a>
				<p></p>
			</div>
		</div>
		<!-- End of Header Section -->
		<div class="password_txt top-4">
			<p v-if="mode === 0">간편비밀번호 6자리를<br />등록해주세요.</p>
			<p v-if="mode === 1">6자리 비밀번호를<br />확인해주세요.</p>
		</div>
		<div class="password-save">
			<ul class="pass-dots">
				<li v-for="(item, index) in displayCode" :key="index">
					<span></span>
				</li>
			</ul>
			<!-- 패스워드 오류문구 -->
			<p v-if="showNoMatch">등록한 비밀번호가 일치하지 않습니다.</p>
			<span v-if="showNoMatch">다시 입력해 주세요.</span>
			<!-- //패스워드 오류문구 -->
		</div>
		<ul class="password-board">
			<li @click="keyPadPressed(1)" class="board-num">1</li>
			<li @click="keyPadPressed(2)" class="board-num">2</li>
			<li @click="keyPadPressed(3)" class="board-num">3</li>
			<li @click="keyPadPressed(4)" class="board-num">4</li>
			<li @click="keyPadPressed(5)" class="board-num">5</li>
			<li @click="keyPadPressed(6)" class="board-num">6</li>
			<li @click="keyPadPressed(7)" class="board-num">7</li>
			<li @click="keyPadPressed(8)" class="board-num">8</li>
			<li @click="keyPadPressed(9)" class="board-num">9</li>
			<li @click="keyPadPressed(9999)" class="board-num">취소</li>
			<li @click="keyPadPressed(0)" class="board-num">0</li>
			<li @click="keyPadPressed(-1)" class="board-num">
				<img :src="require('@/assets/img/ic_back.png')" alt="" />
			</li>
		</ul>
	</div>
</template>

<style scoped>
.top-4 {
	padding-top: 4rem;
}
</style>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
	name: 'PassCode',

	data() {
		return {
			mode: 0, // -: 등록, 1: 확인
			passCode: [],
			displayCode: [],

			showNoMatch: false,
		};
	},

	created() {
		console.debug('PassCode created');
	},

	methods: {
		...mapMutations(['savePassCodeState']),
		...mapActions(['doPostSeriveRegister']),

		keyPadPressed(data) {
			if (this.showNoMatch) {
				this.showNoMatch = false;
			}
			if (data === -1) {
				const arr = this.displayCode.slice(0, this.displayCode.length - 1);
				this.displayCode = [...arr];
			} else if (data === 9999) {
				this.displayCode = [];
				this.passCode = [];
				this.mode = 0;
				this.showNoMatch = false;
			} else {
				if (this.mode === 0) {
					if (this.displayCode.length < 6) {
						this.displayCode.push(data);
						if (this.displayCode.length === 6) {
							this.passCode = [...this.displayCode];
							this.mode = 1;
							this.displayCode = [];
						}
					}
				} else {
					if (this.displayCode.length < 6) {
						this.displayCode.push(data);
						if (this.displayCode.length === 6) {
							if (
								JSON.stringify(this.passCode) ===
								JSON.stringify(this.displayCode)
							) {
								// 일치하는 경우 서버로 패스코드 정보 저장 후
								// 다음 단계로 넘어간다.
								console.debug('matched!!!');
								// KPIC_dispatRegPassCode(JSON.stringify(this.passCode));
								this.savePassCodeState(true);
								this.doPostSeriveRegister();
							} else {
								// 일치하지 않는다는 메세지 노출
								console.debug('no matched!!!');
								this.showNoMatch = true;
								this.displayCode = [];
							}
						}
					}
				}
			}
		},
	},
};
</script>
