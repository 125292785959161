<template>
	<div class="m33 wrapper">
		<div class="top-nav">
			<div class="icon-txt">
				<a @click="goBack">
					<div class="top-iconBack">
						<img :src="require('@/assets/img/ic_back.svg')" alt="뒤로가기" />
					</div>
				</a>
				<p>기본정보 등록</p>
			</div>
		</div>
		<!-- End of Header Section -->
		<div class="basic-info top-4">
			<div class="box-info">
				<p>이름</p>
				<div class="radio_box">
					<label>{{ registerState.userInfo.name }}</label>
				</div>
			</div>
			<div class="box-info">
				<p>성별</p>
				<div class="radio_box">
					<!-- <input
						type="radio"
						v-model="registerState.userInfo.sex"
						class="d-inline"
						value="M"
						id="radio1"
					/> -->
					<label>{{ registerState.userInfo.sex === 'M' ? '남' : '여' }}</label>
					<!-- <input
						type="radio"
						v-model="registerState.userInfo.sex"
						class="d-inline"
						value="F"
						id="radio2"
					/>
					<label for="radio2">여</label> -->
				</div>
			</div>

			<div class="box-info">
				<p>국적</p>
				<div class="radio_box">
					<label>{{
						registerState.userInfo.national === 1 ? '내국인' : '외국인'
					}}</label>
				</div>
			</div>

			<div class="box-info">
				<p>생년월일</p>
				<div class="radio_box">
					<label>{{ hyphonBirth }}</label>
				</div>
				<!-- <Datepicker
					:language="lang"
					v-model="registerState.userInfo.birth"
					:format="customFormatter"
					placeholder="생년월일을 선택해주세요"
				/> -->
			</div>

			<div class="box-info">
				<p>휴대폰번호</p>
				<div class="radio_box">
					<label>{{ hyphenHpNum }}</label>
				</div>
			</div>
			<div class="box-info">
				<p>이메일</p>
				<div class="box-email">
					<input
						type="email"
						v-model="registerState.userInfo.email1"
						placeholder="email"
						class="email"
					/>
					<span>@</span>
					<input
						type="email"
						placeholder="post.co.kr"
						v-model="registerState.userInfo.email2"
						class="domain"
					/>
				</div>
			</div>
			<div class="box-info pb-4">
				<p>주소</p>
				<div class="search-place">
					<input
						type="text"
						readonly="readonly"
						autocomplete="off"
						v-model="registerState.userInfo.address.zipNo"
						placeholder="우편번호"
					/>
					<button type="button" @click="goPage('SearchAddress')">
						주소검색
					</button>
				</div>
				<input
					type="text"
					readonly="readonly"
					autocomplete="off"
					v-model="registerState.userInfo.address.roadAddr"
					placeholder="도로명 주소"
				/>
				<input
					type="text"
					readonly="readonly"
					autocomplete="off"
					v-model="registerState.userInfo.address.jibunAddr"
					placeholder="지번 주소"
				/>
				<input
					v-model="registerState.userInfo.addressDetail"
					type="text"
					placeholder="상세주소 입력"
				/>
			</div>
			<div class="box-shadow"></div>
		</div>

		<div class="btn-app">
			<button type="button" class="btn-select" @click="doNext">다음</button>
		</div>
	</div>
</template>

<style scoped>
.top-4 {
	padding-top: 4rem;
}
</style>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { ko } from 'vuejs-datepicker/dist/locale';
import { KPIC_dispatchCreateDID } from '@/util/appBridge';
import { KPICApi } from '@/api';

export default {
	name: 'UserInfo',

	computed: {
		...mapGetters(['registerState']),

		hyphenHpNum() {
			return this.registerState.userInfo.hp.replace(
				/^(\d{2,3})(\d{3,4})(\d{4})$/,
				`$1-$2-$3`,
			);
		},

		hyphonBirth() {
			return this.registerState.userInfo.birth.replace(
				/(\d{4})(\d{2})(\d{2})/,
				'$1-$2-$3',
			);
		},
	},

	data() {
		return {
			lang: ko,
			date: null,
		};
	},

	created() {
		this.data = this.$moment().toDate();
	},

	methods: {
		...mapMutations(['saveUserInfoState', 'updateLoadingProgressState']),
		...mapActions(['goBack', 'doPostSeriveRegister']),

		doNext() {
			if (this.registerState.userInfo.name.length === 0) {
				alert('이름을 입력해주세요.');
				return;
			}

			if (this.registerState.userInfo.sex.length === 0) {
				alert('생년월일을 입력해주세요.');
				return;
			}

			if (this.registerState.userInfo.national.length === 0) {
				alert('국적을 입력해주세요.');
				return;
			}
			console.debug(this.registerState.userInfo.birth);
			if (this.registerState.userInfo.birth.length === 0) {
				alert('생년월일을 입력해주세요.');
				return;
			}

			if (this.registerState.userInfo.email1.length === 0) {
				alert('이메일을 입력해주세요.');
				return;
			}

			if (this.registerState.userInfo.email2.length === 0) {
				alert('이메일을 입력해주세요.');
				return;
			}

			if (this.registerState.userInfo.hp.length === 0) {
				alert('휴대폰번호를 입력해주세요.');
				return;
			}

			if (!(this.registerState.userInfo.address instanceof Object)) {
				alert('주소를 입력해주세요.');
				return;
			}

			this.doRegister();
		},

		doRegister() {
			this.updateLoadingProgressState(true);
			KPIC_dispatchCreateDID();

			window.dispatchCreateDIDCallback = res => {
				window.dispatchCreateDIDCallback = null;
				console.debug('doDIDCallback', res);
				if (res && res.RESULT) {
					if (res.RESULT === 'OK') {
						let zipAdrestyCd = 1;
						let zipadres = this.registerState.userInfo.address.roadAddr;
						if (this.registerState.userInfo.address.roadAddr.length === 0) {
							zipAdrestyCd = 2;
							zipadres = this.registerState.userInfo.address.jibunAddr;
						}
						const param = {
							brthdy: this.registerState.userInfo.birth.replace(/-/g, ''),
							ci: this.registerState.userInfo.ci,
							detladres: this.registerState.userInfo.addressDetail,
							di: this.registerState.userInfo.di,
							email:
								this.registerState.userInfo.email1 +
								'@' +
								this.registerState.userInfo.email2,
							facerecogUseatCd: 'N',
							fngprtrecogUseatCd: 'N',
							kpDid: res.DATA.DID,
							loginUserSn: 1,
							mbtlnum: this.registerState.userInfo.hp.replace(/-/g, ''),
							nltytyCd: this.registerState.userInfo.national,
							nm: this.registerState.userInfo.name,
							ntcnUseatCd: 'Y',
							sbscrbResn: '',
							sexdstn: this.registerState.userInfo.sex,
							zip: this.registerState.userInfo.address.zipNo,
							zipAdrestyCd: zipAdrestyCd,
							zipLnmadres: this.registerState.userInfo.address.jibunAddr,
							zipRdnmadr: this.registerState.userInfo.address.roadAddr,
							zipadres: zipadres,
						};

						KPICApi.registerMemberInfo(param)
							.then(({ data }) => {
								this.updateLoadingProgressState(false);
								if (data.RESULT_CD === '1') {
									console.debug(data);
									this.saveUserInfoState(true);
									this.doPostSeriveRegister();
								} else {
									alert(data.RESULT_MSSAGE);
								}
							})
							.catch(err => {
								this.updateLoadingProgressState(false);
								console.debug(err);
								alert(err);
							});
					} else {
						this.updateLoadingProgressState(false);
					}
				} else {
					this.updateLoadingProgressState(false);
				}
			};
		},

		goPage(where) {
			this.$router.push({ name: where });
		},
	},
};
</script>
