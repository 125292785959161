<template>
	<div>
		<ThirdrdConsent v-if="what === 'ThirdrdConsent'" />
		<MarketingConsent v-if="what === 'MarketingConsent'" />
		<PostAppConsent v-if="what === 'PostAppConsent'" />
		<PostAppTerms v-if="what === 'PostAppTerms'" />
		<PrivacyConsent v-if="what === 'PrivacyConsent'" />
	</div>
</template>

<script>
import ThirdrdConsent from '@/components/terms/sub/3rdConsent';
import MarketingConsent from '@/components/terms/sub/MarketingConsent';
import PostAppConsent from '@/components/terms/sub/PostAppConsent';
import PostAppTerms from '@/components/terms/sub/PostAppTerms';
import PrivacyConsent from '@/components/terms/sub/PrivacyConsent';

export default {
	name: 'TermsViewer',

	props: {
		what: {
			required: true,
			type: String,
		},
	},

	components: {
		ThirdrdConsent,
		MarketingConsent,
		PostAppConsent,
		PostAppTerms,
		PrivacyConsent,
	},
};
</script>
