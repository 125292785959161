<template>
	<div class="m23 wrapper">
		<img :src="require('@/assets/img/intro_1.png')" />
		<a @click="goPage('GuideRegisterForCorp')">법인회원 가입안내</a>
		<!-- 버튼 -->
		<div class="btn-app">
			<button type="button" class="btn-select" @click="doRegister">
				가입하기
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
	name: 'Register',

	computed: {
		...mapGetters(['registerState']),
	},

	created() {
		console.debug('created!!!');
		// window.checkPermissionCallback = this.checkPermissionCallback;
	},

	methods: {
		...mapMutations([
			'saveRegisterProgressState',
			'updateLoadingProgressState',
		]),

		goPage(what) {
			this.$router.push({ name: what, params: {} });
		},

		doRegister() {
			// native에 웹 권한 설정 여부를 요청한다.
			this.goPage('Register');
		},

		checkPermissionCallback(res) {
			console.debug(res);
			// 앱권한이 없을 경우 앱 권한 요청 화면으로 전환
			const hasAppPermission = false;
			if (!hasAppPermission) {
				this.$router.push({ name: 'GuideAppPermission' });
			} else {
				//
			}
		},
	},
};
</script>

<style></style>
