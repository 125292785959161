<template>
	<div class="m36 wrapper completed">
		<div v-show="isComp" class="box-completed">
			<div class="join-contents">
				<img
					:src="require('@/assets/img/ic_check_img.png')"
					alt="가입완료 이미지"
				/>
				<p>{{ msg }}</p>
			</div>
			<div class="join-txt">
				<p>
					우체국 통합DID로 우체국과 다양한 서비스 (우편, 모바일 우편함, 쇼핑,
					예금, 보험 등)를 한번의 로그인으로 편리하게 이용하세요.
				</p>
				<div class="box-shadow"></div>
			</div>
		</div>

		<div v-show="isComp" class="btn-app">
			<button type="button" class="btn-select" @click="doConfirm">확인</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PostAppRegisterComplete',

	data() {
		return {
			isComp: true,
			msg: '가입완료',
		};
	},

	methods: {
		doConfirm() {
			this.$router.replace({
				path: '/',
			});
		},
	},
};
</script>
