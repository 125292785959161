<template>
	<div class="m36 wrapper error">
		<div class="bio-pass">
			<p>생체정보 등록</p>
			<span>지문을 등록해 주세요</span>
			<!-- <button type="button" @click="doSkip">건너뛰기</button> -->
		</div>
		<div class="bio-img">
			<!-- <p>지문이 인식되지 않습니다.<br />다시 시도해 주세요.</p> -->
			<img :src="require('@/assets/img/btn_m_fingerprint.svg')" alt="지문" />
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
	name: 'FingerPrint',

	methods: {
		...mapActions(['doPostSeriveRegister']),
		...mapMutations(['saveFingerPrintState']),

		doSkip() {
			// this.saveFingerPrintState(2);
			// this.doPostSeriveRegister();
		},
	},
};
</script>
